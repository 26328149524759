<script setup lang="ts">
const { isCrawler, isMobile } = useDevice()

const consentCookie = useCookie('zaraz-consent')
const consentVisible = computed(() => {
  if (isCrawler) return false
  if (consentCookie.value) return false

  return true
})

if (!isCrawler) {
  useScript('https://cdn.gravitec.net/storage/a9eeee3750ed3ea57b614a7b795a2479/client.js', {
    bundle: true,
  })
}

const { data: user } = await useAsyncData<string>('user', () => {
  const headers = useRequestHeaders(['cookie'])
  const cookies = headers?.cookie?.split(' ') || []

  const wpSettingsCookie: string | undefined = cookies.find(k => k.includes('wp-settings-time'))

  if (!wpSettingsCookie)
    return null

  const userCookieParts = wpSettingsCookie.split('wp-settings-time-')
  const userCookie = userCookieParts.length > 1 ? userCookieParts[1] : null

  if (!userCookie)
    return null

  const userId = userCookie?.slice(0, userCookie.indexOf('='))

  return userId
})
</script>

<template>
  <NuxtLoadingIndicator
    :height="4"
    color="#ff5a1f"
  />
  <div
    class="font-sans flex flex-col h-screen bg-#fbfbfb"
    :class="{ 'pt-8': user }"
  >
    <LazyAdminBar
      v-if="user"
      :id="user"
    />

    <Html class="scroll-smooth">
      <Head>
        <Link
          rel="icon"
          type="image/png"
          href="/icon.png"
        />
      </Head>
    </Html>

    <AppNavigation />

    <AppMarquee v-if="isMobile" />

    <main class="flex-grow bg-#fbfbfb">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </main>

    <AppFooter />

    <div
      v-if="isMobile"
      class="sticky inset-x-0 bottom-0"
    >
      <MobileMenu />
    </div>

    <div
      v-if="!isMobile"
      class="sticky inset-x-0 bottom-0"
    >
      <AlertScam />
      <AppMarquee />
    </div>
  </div>

  <LazyModalConsent v-if="consentVisible" />
</template>

<style>
.userfeedback-widget {
  --uf-input-border-color: #334155 !important;
  --uf-input-bg-color: #1e293b !important;
}

.userfeedback-widget:not(.is-preview).widget-position-middle_right .userfeedback-widget__toggle {
  top: 80% !important;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
</style>
