export default defineAppConfig({
  ui: {
    primary: 'orange',
    badge: {
      font: 'font-semibold',
      rounded: 'rounded',
    },

    input: {
      rounded: 'rounded',
    },

    button: {
      rounded: 'rounded',
    },

    popover: {
      base: 'text-gray-100 text-xs px-3 py-2',
      ring: 'ring-gray-800',
      width: 'max-w-sm',
      background: 'bg-gray-900',
      arrow: {
        ring: 'before:ring-gray-800',
        background: 'before:bg-gray-900',
      },
    },

    container: {
      constrained: 'max-w-[90rem]',
    },

    landing: {
      section: {
        base: 'text-left items-start',
        title: 'lg:text-4xl',
        wrapper: 'py-8 sm:py-10',
        container: 'gap-y-4 sm:gap-y-6',
        description: 'mt-2',
      },
    },

    page: {
      body: { wrapper: 'mt-4 pb-4' },
    },
  },

  widget: {
    amount: 500,
    minimum: {
      PLN: 100,
      EUR: 25,
      USD: 25,
    },
    currency: {
      fiat: 'PLN',
      crypto: 'BTC',
      object: {
        code: 'PLN',
        avatar: {
          src: 'https://gateway.ari10.com/images/PLNIcon.svg',
        },
      },
    },
  },

  infiniteScroll: {
    enabled: true,
    perPage: 5,
  },

  itemsPerPage: 24,
  editorialEmail: 'redakcja@bitcoin.pl',
})
