<script setup lang="ts">
defineProps({
  item: Object,
})

const isExpanded = ref(false)
</script>

<template>
  <div class="font-sans">
    <NuxtLink
      v-if="!item.children"
      :target="item.target"
      :title="item.title"
      :external="item.external"
      :to="item.to"
      class="group w-full flex items-center pl-10 pr-2 py-2 font-medium text-white rounded hover:text-gray-400"
      transition
      duration="500"
    >
      {{ item.label }}
    </NuxtLink>
    <div
      v-else
      class="space-y-1"
    >
      <button
        class="font-sans group w-full flex items-center pr-2 pl-10 py-2 font-medium hover:text-gray-400"
        x-bind:aria-expanded="isExpanded"
        transition
        duration="500"
        @click.prevent="isExpanded = !isExpanded"
      >
        <svg
          :class="{
            'text-gray-400 rotate-90': isExpanded,
            'text-gray-300': !isExpanded,
          }"
          x-state:on="Expanded"
          x-state:off="Collapsed"
          class="mr-2 size-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150 text-gray-300"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path
            d="M6 6L14 10L6 14V6Z"
            fill="currentColor"
          />
        </svg>
        {{ item.label }}
      </button>
      <div
        v-if="isExpanded"
        class="space-y-1"
      >
        <NuxtLink
          v-for="innerItem in item.children"
          :key="innerItem.id"
          :target="innerItem.target"
          :title="innerItem.title"
          :to="innerItem.to"
          :item="innerItem"
          class="group font-sans w-full flex items-center pl-20 pr-2 py-2 font-medium hover:text-gray-400"
          transition
          duration="500"
        >
          {{ innerItem.label }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
