<script setup lang="ts">
const route = useRoute()

defineProps({
  ident: {
    type: String,
  },
})

const mobile = ref(false)
const search = ref(false)
const s = ref('')

async function onEnter() {
  await navigateTo(`/wyszukiwarka?temat=${s.value}`, { external: true })
}

watch(
  () => route.path,
  () => {
    mobile.value = false
    search.value = false
  },
)

const { data: header } = await useFetch('/api/legacy/menus/header')
</script>

<template>
  <nav
    class="bg-gray-900 text-white"
    style="font-size: clamp(13px, 1vw, 14px)"
  >
    <UContainer class="py-4">
      <div class="flex flex-row items-center justify-between gap-12">
        <section class="block xl:hidden">
          <svg
            class="size-6 text-white cursor-pointer"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            @click="mobile = !mobile"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </section>
        <section
          id="logo"
          class="flex flex-row items-center gap-3"
        >
          <AppLogo />
          <div class="hidden xl:block color-white">
            <SocialMedia />
          </div>
        </section>

        <section
          class="hidden xl:flex-1 xl:flex gap-2 place-content-end"
          data-test-id="mainNav"
        >
          <template
            v-for="item in header"
            :key="item.id"
          >
            <template v-if="item.children">
              <NavigationItem :item="item" />
            </template>
            <template v-else>
              <NuxtLink
                :to="item.to"
                :target="item.target"
                :title="item.title"
                class="font-medium px-3 py-2 text-white hover:text-primary"
                transition
                duration="500"
              >
                {{ item.label }}
              </NuxtLink>
            </template>
          </template>
        </section>
        <NavigationPrice />
        <section class="block">
          <UButton
            icon="i-bx-search-alt"
            color="black"
            aria-label="Wyszukiwarka"
            @click="search = !search"
          />
        </section>
      </div>
    </UContainer>
    <div
      v-if="mobile"
      class="xl:hidden absolute bg-gray-700 w-full z-20 pb-4"
    >
      <div
        v-for="item in header"
        :key="item.id"
        :item="item"
        class="border-b-2 hover:bg-gray-800 border-gray-600 border-opacity-10"
        transition
        duration="500"
      >
        <div v-if="!item.children">
          <NuxtLink
            :to="item.to"
            :target="item.target"
            :title="item.title"
            class="group w-full flex items-center pl-4 py-2 font-medium text-white"
          >
            {{ item.label }}
          </NuxtLink>
        </div>
        <div
          v-else
          class="space-y-1 pl-4"
        >
          <MenuMobileItem :item="item" />
        </div>
      </div>
      <SocialMedia />
    </div>
  </nav>
  <div
    v-if="search"
    class="bg-gray-800 border-y border-gray-700/50 focus-within:border-gray-600 transition-all"
  >
    <UContainer>
      <div class="relative shadow-sm">
        <input
          v-model="s"
          type="search"
          class="w-full bg-gray-800 focus:outline-none focus:ring-none focus:border-none py-4 text-gray-100"
          placeholder="Wpisz wyszukiwaną frazę i kliknij enter..."
          @keyup.enter="onEnter"
        >
        <button
          aria-label="Szukaj"
          @click="onEnter"
        >
          <UIcon
            name="i-bx-search-alt"
            class="font-semibold transition-colors duration-300 size-6 absolute top-4 right-4 text-gray-400 hover:text-gray-200"
          />
        </button>
      </div>
    </UContainer>
  </div>
</template>
