<template>
  <NuxtLink to="/">
    <NuxtImg
      src="/logo-white.webp"
      alt="bitcoin.pl logo"
      width="128"
      height="32"
      format="avif"
      preload
    />
  </NuxtLink>
</template>
