<script setup lang="ts">
defineProps({
  item: Object,
})

const flyoutMenuOpen = ref(false)
</script>

<template>
  <div>
    <div
      class="relative px-0.5 rounded font-medium leading-5 text-gray-100 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
      @mouseover="flyoutMenuOpen = true"
      @mouseleave="flyoutMenuOpen = false"
    >
      <button
        type="button"
        class="font-sans inline-flex items-center justify-between px-3 py-2 font-medium transition-all duration-500 rounded-md focus:outline-none focus:text-brand-900 sm:focus:shadow-outline"
        :class="{ 'bg-gray-900 text-primary': flyoutMenuOpen }"
        data-test-class="dropdownNavItem"
      >
        <span class="flex-shrink-0">{{ item.label }}</span>
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="flex-shrink-0 size-5 ml-1"
        >
          <path
            :class="{ 'rotate-180': flyoutMenuOpen }"
            class="transition duration-300 ease-in-out origin-center transform"
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <Transition
        enter-active-class="transition duration-500 ease-out transform"
        enter-class="-translate-y-3 scale-95 opacity-0"
        enter-to-class="translate-y-0 scale-100 opacity-100"
        leave-active-class="transition duration-150 ease-in transform"
        leave-class="translate-y-0 opacity-100"
        leave-to-class="-translate-y-3 opacity-0"
      >
        <div
          v-if="flyoutMenuOpen"
          style="z-index: 99"
          class="absolute pt-2 w-screen max-w-md"
        >
          <div class="absolute bg-gray-50 rounded shadow">
            <div>
              <NavigationItemChild
                v-for="innerItem in item.children"
                :key="innerItem.id"
                :item="innerItem"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
