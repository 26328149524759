<script setup lang="ts">
import { ref } from 'vue'

defineProps({
  item: Object,
})

const flyoutMenuOpen = ref(false)
</script>

<template>
  <div
    class="relative flex flex-col"
    @mouseover="flyoutMenuOpen = true"
    @mouseleave="flyoutMenuOpen = false"
  >
    <NavItem :item="item" />

    <Transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="-translate-y-3 scale-95 opacity-0"
      enter-to-class="translate-y-0 scale-100 opacity-100"
      leave-active-class="transition duration-150 ease-in transform"
      leave-class="translate-y-0 opaciiity-100"
      leave-to-class="-translate-y-3 opacity-0"
    >
      <div
        v-if="flyoutMenuOpen"
        class="absolute pt-2 w-screen max-w-md"
        style="right: 448px"
      >
        <div class="relative bg-gray-50 rounded rounded-r-none shadow">
          <template
            v-for="itemInner in item.children"
            :key="itemInner.id"
          >
            <NavItem :item="itemInner" />
          </template>
        </div>
      </div>
    </Transition>
  </div>
</template>
