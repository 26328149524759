<script setup lang="ts">
const { data } = await useFetch('/api/legacy/menus/footer')
</script>

<template>
  <UFooter class="dark bg-gray-900">
    <template #top>
      <UFooterColumns :links="data">
        <template #left>
          <div class="space-y-4 max-w-sm">
            <AppLogo />
            <UPageBody prose>
              <p>Bitcoin, kryptowaluty i technologia blockchain. Wszystko co chciałbyś wiedzieć, w jednym miejscu.</p>
              <p>Copyright © {{ new Date().getFullYear() }}</p>
            </UPageBody>
          </div>
        </template>
      </UFooterColumns>
    </template>
  </UFooter>
</template>
