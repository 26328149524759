<script setup lang="ts">
const { data: items } = await useFetch('/api/tickers')

function usePercentFormat(percents: number) {
  return new Intl.NumberFormat('pl-PL', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  }).format(percents / 100)
}

onMounted(() => {
  const buttons = document.querySelectorAll('.shadow-arrows')

  buttons.forEach((button) => {
    if (button.querySelector('.i-bitcoin-icons-caret-right-filled'))
      button.setAttribute('aria-label', 'Następny')
    else if (button.querySelector('.i-bitcoin-icons-caret-left-filled'))
      button.setAttribute('aria-label', 'Poprzedni')
  })
})
</script>

<template>
  <div class="bg-gray-900/95 border-b-2 border-gray-700/25 text-gray-100">
    <UCarousel
      v-slot="{ item }"
      :items
      :ui="{ item: 'snap-start' }"
      arrows
      class="first:px-8"
    >
      <NuxtLink
        :to="item.symbol === 'BTC' ? '/kurs-bitcoin' : '/kurs-kryptowalut'"
        external
      >
        <div class="flex justify-center items-center gap-2 py-2 px-8 hover:bg-gray-900 rounded transition-colors">
          <NuxtImg
            :alt="`${item.name} logo`"
            loading="lazy"
            format="avif"
            height="25"
            width="25"
            :src="`https://static.coinpaprika.com/coin/${item.id}/logo.png`"
          />
          <div class="flex gap-4">
            <div class="flex flex-col">
              <span class="font-semibold text-sm truncate">{{ item.name }}</span>
              <span class="font-mono font-medium text-xs text-gray-300">
                {{ item.symbol }}
              </span>
            </div>
            <div class="flex flex-col text-right">
              <span class="font-semibold text-sm">
                {{ item.price.USD }}</span>
              <span
                class="font-medium font-mono text-xs"
                :class="[
                  item.change < 0
                    ? 'text-red-500'
                    : 'text-green-500',
                ]"
              >{{
                usePercentFormat(item.change)
              }}</span>
            </div>
          </div>
        </div>
      </NuxtLink>
    </UCarousel>
  </div>
</template>
