<script setup lang="ts">
const { data } = await useFetch('/api/legacy/menus/mobile')
</script>

<template>
  <div class="bg-gray-900 backdrop-blur flex border-t-2 border-gray-800 shadow">
    <template
      v-for="item, i in data"
      :key="item"
    >
      <div
        v-if="i === 2"
        class="cen flex items-center justify-center mx-4"
      >
        <NuxtLink
          :to="item.to"
          class="flex absolute mb-14 transition-colors shadow-xl p-2 rounded-full bg-primary"
        >
          <UIcon
            name="token:bitcoin"
            class="size-10 text-white"
          />
        </NuxtLink>
        <div class="text-[10px] mt-8 font-medium text-white">
          {{ item.label }}
        </div>
      </div>
      <NuxtLink
        v-else
        :to="item.to"
        class="text-white flex-1 flex flex-col items-center p-2 hover:bg-primary/5 transition-colors"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="item.acf?.emoji"
          class="size-6"
          v-html="item.acf.emoji"
        />

        <UIcon
          v-else-if="item.acf?.icon"
          :name="item.acf.icon"
          class="size-6"
        />
        <div class="text-[10px] grow mt-1 font-medium flex flex-col items-center text-center justify-center">
          {{ item.label }}
        </div>
      </NuxtLink>
    </template>
  </div>
</template>
