<script setup lang="ts">
const priceHover = ref(false)

const { data } = await useFetch('/api/tickers')
</script>

<template>
  <section class="hidden xl:block slashed-zero font-semibold">
    <NuxtLink
      v-if="data"
      to="/kurs-bitcoin"
      external
      class="duration-300 text-white hover:text-primary transition-colors flex items-center gap-2"
      @mouseover="priceHover = !priceHover"
    >
      <UIcon
        name="i-bitcoin-icons-bitcoin-circle-filled"
        class="size-6"
      />
      <span>{{
        priceHover
          ? data[0].price['USD']
          : data[0].price['PLN']
      }}</span>
    </NuxtLink>
  </section>
</template>
