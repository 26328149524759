<script setup lang="ts">
const ui = {
  variant: {
    solid: 'shadow-sm text-white bg-{color}-600 hover:bg-{color}-400 disabled:bg-{color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-{color}-500',
  },
}

const alertClosed = ref(false)

const { data } = await useFetch('/api/legacy/menus/alert')
</script>

<template>
  <div
    v-if="!alertClosed && data"
    class="bg-primary p-2.5 px-6 text-xs lg:text-sm text-white font-medium border-t border-primary-400 shadow-2xl shadow-primary"
  >
    <UContainer>
      <div class="flex items-center gap-4">
        <div class="grow flex flex-inline items-center gap-2">
          <UIcon
            name="i-heroicons-shield-exclamation"
            class="h-10 w-10 lg:h-5 lg:w-5 text-primary-100"
          />
          <NuxtLink :to="data.to">
            {{ data.description }}
          </NuxtLink>
        </div>
        <div class="hidden lg:flex gap-2">
          <UButton
            v-if="data.to"
            :to="data.to"
            size="xs"
            class="grow"
            color="orange"
            :ui
            icon="i-heroicons-ellipsis-horizontal-circle"
          >
            {{ data.label }}
          </UButton>
          <UButton
            size="xs"
            color="orange"
            :ui
            icon="i-heroicons-x-circle"
            @click="alertClosed = true"
          >
            Zamknij
          </UButton>
        </div>
        <div class="flex lg:hidden">
          <UButton
            size="xs"
            color="orange"
            :ui
            icon="i-heroicons-x-circle"
            aria-label="zamknij"
            @click="alertClosed = true"
          />
        </div>
      </div>
    </UContainer>
  </div>
</template>
