<script setup lang="ts">
defineProps({
  item: Object,
})

const isExpanded = ref(false)
</script>

<template>
  <div class="font-sans">
    <button
      class="font-sans group w-full flex items-center pr-2 py-2 font-medium text-white"
      x-bind:aria-expanded="isExpanded"
      @click.prevent="isExpanded = !isExpanded"
    >
      <svg
        :class="{
          'text-gray-400 rotate-90': isExpanded,
          'text-gray-300': !isExpanded,
        }"
        x-state:on="Expanded"
        x-state:off="Collapsed"
        class="mr-2 size-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150 text-gray-300"
        viewBox="0 0 20 20"
        aria-hidden="true"
      >
        <path
          d="M6 6L14 10L6 14V6Z"
          fill="currentColor"
        />
      </svg>
      {{ item.label }}
    </button>
    <template v-if="isExpanded">
      <div
        v-for="itemInner in item.children"
        :key="itemInner.id"
        :item="itemInner"
        x-description="Expandable link section, show/hide based on state."
        class="space-y-1 font-sans"
      >
        <MenuMobileSubItem :item="itemInner" />
      </div>
    </template>
  </div>
</template>
